<template>
  <b-modal
    id="modal-room-detail"
    ref="refModalRoomDetail"
    title="Thông tin phòng"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal">
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        1. Thông tin phòng
      </div>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tên phòng:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tòa nhà:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tầng:
            </b-col>
            <b-col class="">
              {{ itemLocal.floor.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Giá thuê:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.price).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Đặt cọc:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.deposit).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái hoạt động:
            </b-col>
            <b-col class="">
              {{ itemLocal.active ? 'Đang hoạt động' : 'Ngừng sử dụng' }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.active === true && itemLocal.status">
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái thuê:
            </b-col>
            <b-col class="">
              {{ itemLocal.status.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="isPublic && itemLocal.emptyDate">
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày có thể chuyển vào:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.emptyDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <div v-if="!isPublic">
        <div

          class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary py-1"
        >
          2. Đặt cọc
        </div>
        <reservation-table
          v-if="itemLocal.reservations && itemLocal.reservations.length > 0"
          :data="itemLocal.reservations"
        />
        <b-alert
          v-else
          show
          variant="warning"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Không có thông tin đặt đặt cọc.</span>
          </div>
        </b-alert>
        <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-1">
          3. Hợp đồng
        </div>
        <contract-table
          v-if="itemLocal.contracts && itemLocal.contracts.length > 0"
          :data="itemLocal.contracts"
        />
        <b-alert
          v-else
          show
          variant="warning"
          class="mt-1"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Không có thông tin hợp đồng.</span>
          </div>
        </b-alert>
        <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-1">
          4. Khách thuê
        </div>
        <tenant-table
          v-if="itemLocal.tenants && itemLocal.tenants.length > 0"
          :rows="itemLocal.tenants"
        />
        <b-alert
          v-else
          show
          variant="warning"
          class="mt-1"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Không có thông tin khách hàng.</span>
          </div>
        </b-alert>
      </div>

      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-1">
        5. Tài sản
      </div>

      <asset-table
        v-if="itemLocal.assets && itemLocal.assets.length > 0"
        :data="itemLocal.assets"
      />
      <b-alert
        v-else
        show
        class="mt-1"
        variant="warning"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">Không có thông tin về tài sản.</span>
        </div>
      </b-alert>
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        6. Hình ảnh phòng
      </div>
      <media-view
        v-model="itemLocal.attachments"
        :label="null"
        :initial-value="itemLocal.attachments"
        :upload-imediately="true"
        :target-object="{id: itemLocal.id, type: 'room'}"
        :preview-mode="!$can('update', 'room') || isPublic"
        @on-delete-uploaded-file="onDeleteAttachment"
      />
    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BAlert,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import MediaView from '@/views/components/media/MediaView.vue';
import { parseDateToString } from '@/auth/utils';
import TenantTable from './TenantTable.vue';
import AssetTable from './AssetTable.vue';
import useRoomDetailModal from './useRoomDetailModal';
import ContractTable from './ContractTable.vue';
import ReservationTable from './ReservationTable.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    AssetTable,
    TenantTable,
    BSpinner,
    ContractTable,
    BAlert,
    ReservationTable,
    MediaView,
  },
  props: {
    roomId: {
      type: Number,
      default: null,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      onDeleteAttachment,
    } = useRoomDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      onDeleteAttachment,
      parseDateToString,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
