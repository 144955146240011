import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import Vue from 'vue';

import { getUserConfiguration } from '@/auth/utils';
import apartmentStoreModule from '../apartmentStoreModule';

export default function useApartmentModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'apartment';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, apartmentStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const isSubmitting = ref(false);
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const userConfiguration = getUserConfiguration();
  const enableTingee = userConfiguration.enableTingee ?? false;

  const apartmentId = computed(() => props.apartmentId);
  const isLoading = ref(false);

  const fetchDetailData = () => {
    if (apartmentId.value) {
      isLoading.value = true;
      store.dispatch('apartment/getApartmentDetail', apartmentId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      locationId: itemLocal.value.location ? itemLocal.value.location.id : null,
      invoiceTemplateId: itemLocal.value.invoiceTemplate ? itemLocal.value.invoiceTemplate.id : null,
      contractTemplateId: itemLocal.value.contractTemplate ? itemLocal.value.contractTemplate.id : null,
      provinceId: itemLocal.value.province.id,
      districtId: itemLocal.value.district.id,
      wardId: itemLocal.value.ward.id,
      address: itemLocal.value.address,
      active: itemLocal.value.active,
      paymentDay: itemLocal.value.paymentDay,
      feeIds: itemLocal.value.fees && itemLocal.value.fees.length > 0 ? itemLocal.value.fees.map(object => object.id) : [],
    };
    if (itemLocal.value.tingee && itemLocal.value.tingee.id > 0) {
      data.tingeeId = itemLocal.value.tingee.id;
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('apartment/updateApartment', data)
        .then(() => {
          isSubmitting.value = false;
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('apartment/createApartment', data)
        .then(response => { emit('on-item-created', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  const onAddServices = selectedServices => {
    if (itemLocal.value.fees) {
      const newServices = selectedServices.filter(object => !itemLocal.value.fees.map(fee => fee.id).includes(object.id));
      Vue.set(itemLocal.value, "fees", [...itemLocal.value.fees, ...newServices]);
    } else {
      itemLocal.value.fees = selectedServices;
    }
  };

  const removeFee = index => {
    itemLocal.value.fees.splice(index, 1);
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    isTingTong,
    isLoading,
    enableTingee,
    resetItemLocal,
    resetModal,
    onSubmit,
    onAddServices,
    onOpen,
    removeFee,
  };
}
