<template>
  <div class="room-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Báo cáo tài chính theo năm</b-card-title>

      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col md="6">
            <select-year v-model="year" />
          </b-col>
          <b-col md="6">
            <select-apartment v-model="apartment" />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <cash-flow-chart-by-year
              :apartment="apartment"
              :year="year ? year.value : null"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <cash-flow-by-year-html
              :apartment="apartment"
              :year="year ? year.value : null"
            />
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12">
            <income-expense-by-type
              :apartment="apartment"
              :year="year ? year.value : null"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <room-detail-modal :room-id="item.id" />
  </div>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
// eslint-disable-next-line import/no-cycle
import RoomDetailModal from '@/views/property/room/detail/RoomDetailModal.vue';
import SelectYear from '@/views/components/SelectYear.vue';
import useReportCashFlow from './useReportCashFlow';
import CashFlowChartByYear from './CashFlowChartByYear.vue';
import CashFlowByYearHtml from './CashFlowByYearHtml.vue';
import IncomeExpenseByType from './IncomeExpenseByType.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    SelectApartment,
    RoomDetailModal,
    CashFlowChartByYear,
    CashFlowByYearHtml,
    IncomeExpenseByType,
    SelectYear,
  },
  setup() {
    const {
      item,
      apartment,
      year,

      resetItem,
    } = useReportCashFlow();

    return {
      item,
      apartment,
      year,

      resetItem,
    };
  },

  created() {

  },

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.room-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 100px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 100px !important;
            }
            &:nth-child(8) {
               min-width: 120px !important;
               width: 120px !important;
            }
            &:nth-child(9) {
               min-width: 100px !important;

            }
          }
        }
      }
    }
}
</style>
