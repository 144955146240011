<template>
  <b-modal
    id="modal-quota"
    ref="refModal"
    title="Định mức"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="StarIcon"
        />
        <span>Hướng dẫn cài định mức:</span>
        <div class="ml-25">
          - Định mức 1 = 100; Đơn giá định mức 1: 1.285. Tức là 100 KW đầu giá là 1.285
        </div>
        <div class="ml-25">
          - Định mức 2 = 50; Đơn giá định mức 2: 1.336. Tức là 50 KW tiếp theo giá là 1.336
        </div>
      </div>
    </b-alert>
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên định mức"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ZapIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Điện"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label="Mô tả">
              <b-form-textarea
                id="description"
                v-model="itemLocal.description"
                placeholder="Mô tả"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) in itemLocal.items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col
            cols="6"
            sm="5"
          >
            <validation-provider
              #default="validationContext"
              :name="`quantity-${item.id}`"
              rules="required"
            >
              <number-input
                v-model="item.quantity"
                :label="`Định mức ${index + 1}`"
                placeholder="50"
                :class="['input-number-format',{error:validationContext.errors[0] }]"
                :default-value="item.quantity"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            cols="6"
            sm="5"
          >
            <validation-provider
              #default="validationContext"
              :name="`price-${item.id}`"
              rules="required"
            >
              <number-input
                v-model="item.price"
                :label="`Đơn giá định mức ${index + 1}`"
                placeholder="4,000"
                :class="['input-number-format',{error:validationContext.errors[0] }]"
                :default-value="item.price"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            cols="3"
            sm="2"
            class="px-0 mt-sm-2"
          >
            <b-button
              variant="outline-danger"
              class="btn px-1 btn-outline-danger ml-1 ml-sm-auto float-sm-right mr-sm-1 w-90 min-w-75"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Xoá</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <div class="d-flex">
          <b-button
            variant="outline-warning"
            class="w-175"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Thêm định mức</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormTextarea,
  BButton,
  BAlert,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import useQuotaModal from './useQuotaModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAlert,
    NumberInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      instructions,
      resetItemLocal,
      resetModal,
      repeateAgain,
      onSubmit,
      removeItem,
    } = useQuotaModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      instructions,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      repeateAgain,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      removeItem,
    };
  },
};
</script>
