<template>
  <b-modal
    id="modal-fee"
    ref="refModal"
    title="Dịch vụ"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col :md="itemLocal.default ? 12 : 6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên dịch vụ"
              >
                <template
                  v-slot:label
                >
                  Tên dịch vụ
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ZapIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Điện"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            v-if="!itemLocal.default"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="feeCategory"
              rules="required"
            >
              <select-fee-type
                v-model="itemLocal.feeCategory"
                required
                :default-value="itemLocal.feeCategory"
                label="Loại phí"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="!itemLocal.default">
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Loại đơn giá"
              rules="required"
            >
              <b-form-group>
                <select-unit-price
                  v-model="itemLocal.type"
                  required
                  :default-value="itemLocal.type"
                  label="Loại đơn giá"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
                <b-form-text
                  v-if="itemLocal.type && itemLocal.type.id === 4"
                >Đơn giá biến động sẽ được nhập khi bạn lập hoá
                  đơn</b-form-text>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="
              itemLocal.type &&
                (itemLocal.type.id == 1 || itemLocal.type.id == 3)
            "
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="price"
              rules="required"
            >
              <number-input
                v-model="itemLocal.price"
                required
                :default-value="itemLocal.price"
                label="Đơn giá"
                placeholder="100,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="
              itemLocal.type &&
                (itemLocal.type.id == 1 ||
                  itemLocal.type.id == 3 ||
                  itemLocal.type.id == 4)
            "
            :md="itemLocal.type && itemLocal.type.id === 4 ? 6 : 3"
          >
            <validation-provider
              #default="validationContext"
              name="unit"
              rules="required"
            >
              <select-unit
                v-model="itemLocal.unit"
                required
                :default-value="itemLocal.unit"
                label="Đơn vị tính"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="itemLocal.type && (itemLocal.type.id === 2 || itemLocal.type.id === 5)"
            md="6"
          >
            <select-quota
              v-model="itemLocal.quota"
              required
              :default-value="itemLocal.quota"
              :enable-add="true"
              label="Chọn định mức"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Mô tả">
              <b-form-textarea
                id="description"
                v-model="itemLocal.description"
                placeholder="Mô tả"
                rows="3"
              />
            </b-form-group>

          </b-col>

        </b-row>
        <b-row>
          <b-col cols="12">
            <select-multiple-apartment
              v-model="itemLocal.apartments"
              label="Tòa nhà sử dụng"
              required
              :default-value="itemLocal.apartments"
              class="select-apartment"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormTextarea,
  BFormText,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectFeeType from '@/views/components/SelectFeeType.vue';
import SelectUnitPrice from '@/views/components/SelectUnitPrice.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectUnit from '@/views/components/SelectUnit.vue';
import SelectQuota from '@/views/components/SelectQuota.vue';
import SelectMultipleApartment from '@/views/components/SelectMultipleApartment.vue';
import useFeeModal from './useFeeModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormTextarea,
    BFormText,
    ValidationProvider,
    ValidationObserver,

    SelectFeeType,
    SelectUnitPrice,
    NumberInput,
    SelectUnit,
    SelectQuota,
    SelectMultipleApartment,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useFeeModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
