import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLocations(ctx, params) {
      return useJwt.getLocations(params).then(response => response);
    },
    createLocation(ctx, data) {
      return useJwt.createLocation(data).then(response => response);
    },
    updateLocation(ctx, data) {
      return useJwt.updateLocation(data).then(response => response);
    },
    getLocationDetail(ctx, id) {
      return useJwt.getLocationDetail(id).then(response => response);
    },
    deleteLocations(ctx, data) {
      return useJwt.deleteLocations(data).then(response => response);
    },
  },
};
