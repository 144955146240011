<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="quota"
        v-model="quota"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="options"
        placeholder="Chọn định mức"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true && $can('create', 'quota')"
          #list-header
        >
          <li
            v-b-modal.modal-quota
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">Thêm định mức</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <quota-modal
      v-if="enableAdd === true"
      :item="{name: '', description: '', items: []}"
      @refetch-data="fetchDatas"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import QuotaModal from '../services/quota/modal/QuotaModal.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    QuotaModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quota: this.defaultValue,
      options: [],
    };
  },
  created() {
    this.fetchDatas();
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.quota);
    },
    fetchDatas() {
      useJwt.getQuotas().then(res => {
        this.options = res.data.items;
      });
    },
    onOpen() {
      if (this.options.length === 0) {
        this.fetchDatas();
      }
    },
    onItemCreated(val) {
      this.quota = val;
      this.valueChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
