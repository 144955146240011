<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <span class="font-weight-bolder">Biểu đồ dòng tiền thu chi thực tế</span>
    <b-row class="mx-0">
      <b-col
        cols="12"
        class="revenue-report-wrapper"
      >

        <!-- chart -->
        <vue-apex-charts
          v-if="data"
          id="revenue-report-chart"
          type="bar"
          height="480"
          :options="getChartOptions"
          :series="data.series"
        />

      </b-col>
      <b-col cols="12">
        <div class="d-sm-flex justify-content-center align-items-center mb-3">

          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Thu vào</span>
            </div>
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>Chi ra</span>
            </div>
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span>Chênh lệch</span>
            </div>

          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import Ripple from 'vue-ripple-directive';
import useJwt from '@/auth/jwt/useJwt';
import moment from 'moment';

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    apartment: {
      type: Object,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    getChartOptions() {
      const { options } = this.data;
      options.yaxis.labels = {
        ...options.yaxis.labels,
        formatter: y => {
          if (typeof y !== 'undefined') {
            return `${Number(y).toLocaleString()}`;
          }
          return y;
        },
      };
      return options;
    },
  },
  watch: {
    apartment() {
      this.fetchData(this.apartment, this.year);
    },
    year() {
      this.fetchData(this.apartment, this.year);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(apartment, year) {
      useJwt.getIncomeExpenseChartByYear({ apartmentId: apartment ? apartment.id : null, year: year || moment().year() }).then(response => {
        this.data = response.data;
      });
    },
  },

};
</script>
