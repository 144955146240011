<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="data">
    <span class="font-weight-bolder">Bảng thu chi theo tháng và phân loại</span>
    <cite class="mt-1"><br>(Số liệu tính toán trong trường hợp các khoản thu trong hóa đơn có thể thu đầy đủ)</cite>
    <div
      class="mt-2"
      v-html="data"
    />
  </div>

</template>

<script>
import {

} from 'bootstrap-vue';

import Ripple from 'vue-ripple-directive';
import useJwt from '@/auth/jwt/useJwt';
import moment from 'moment';

export default {
  components: {

  },
  directives: {
    Ripple,
  },
  props: {
    apartment: {
      type: Object,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: null,
    };
  },

  watch: {
    apartment() {
      this.fetchData(this.apartment, this.year);
    },
    year() {
      this.fetchData(this.apartment, this.year);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(apartment, year) {
      useJwt.getIncomeExpenseHTMLByType({ apartmentId: apartment ? apartment.id : null, year: year || moment().year() }).then(response => {
        this.data = response.data;
      });
    },
  },

};
</script>
