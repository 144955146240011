<template>
  <div>
    <vue-good-table
      class="mb-1 mt-1"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Code -->
        <span v-if="props.column.field === 'priceForRent'">
          {{ Number(props.row[props.column.field]).toLocaleString() }}
          <span v-if="props.row.bed">({{ props.row.bed.name }})</span>
        </span>
        <span v-else-if="props.column.field === 'deposit'">
          {{ Number(props.row[props.column.field]).toLocaleString() }}
        </span>
        <span v-else-if="props.column.field === 'tenant'">
          <span v-if="props.row.tenant">{{ props.row.tenant.name }}</span>
        </span>
        <span v-else-if="['depositDate', 'checkinDate'].includes(props.column.field)">
          {{ parseDateToString(props.row[props.column.field]) }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

import { parseDateToString } from '@/auth/utils';

export default {
  components: {
    BSpinner,

    VueGoodTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: this.data,
    };
  },
  computed: {
    getRemain() {
      let remain = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of this.rows) {
        remain += row.remain;
      }
      return remain;
    },
  },
  watch: {
    data(val) {
      this.rows = val;
    },
  },
  setup() {
    // Table Handlers
    const columns = [
      {
        label: 'Người cọc',
        field: 'tenant',
        sortable: false,
      },
      {
        label: 'Giá thuê',
        field: 'priceForRent',
        sortable: false,
      },
      {
        label: 'Số tiền cọc',
        field: 'deposit',
        sortable: false,
      },
      {
        label: 'Ngày cọc',
        field: 'depositDate',
        sortable: false,
      },
      {
        label: 'Ngày chuyển vào',
        field: 'checkinDate',
        sortable: false,
      },

    ];

    return {
      columns,
      parseDateToString,
    };
  },
};
</script>
