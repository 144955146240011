<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="unit"
      v-model="unit"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="units"
      placeholder="Đơn vị tính"
      @input="valueChanged"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [Object, String],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unit: this.defaultValue,
      units: [
        { title: 'Kwh', value: 'Kwh' },
        { title: 'm3', value: 'm3' },
        { title: 'm2', value: 'm2' },
        { title: 'Người', value: 'Người' },
        { title: 'Phòng', value: 'Phòng' },
        { title: 'Giường', value: 'Giường' },
        { title: 'Xe', value: 'Xe' },
        { title: 'Lượt/Lần', value: 'Lượt' },
      ],
    };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.unit);
    },

  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
