<template>
  <b-modal
    id="modal-list-service"
    ref="my-modal"
    size="lg"
    title="Danh Sách Dịch Vụ & Phí"
    ok-title="Chọn"
    cancel-title="Hủy"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="!selectedRows.length"
    @hidden="resetValue"
    @show="fetchFees"
    @ok="handleSubmit"
  >
    <b-row>
      <b-col
        lg="6"
        md="12"
      >
        <select-fee-type v-model="type" />
      </b-col>
      <b-col
        lg="4"
        md="8"
      >
        <b-form-input
          v-model="searchTerm"
          class="mb-1"
          placeholder="Tìm kiếm..."
          debounce="1000"
        />
      </b-col>
      <b-col
        lg="2"
        md="4"
      >
        <b-button
          v-b-modal.modal-fee
          variant="primary"
          class="w-100 mr-0 px-0"
        > Thêm mới </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
          }"
          @on-selected-rows-change="selectionChanged"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="loadingContent">
            <b-spinner
              label="Loading"
              type="grow"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Code -->
            <span v-if="props.column.field === 'code'">
              <b-link
                :to="{
                  name: 'apartment-detail',
                  params: { id: props.row.id },
                }"
                class="font-weight-bold"
              >
                {{ props.row.code }}
              </b-link>
            </span>

            <!-- Column: Common -->
            <span v-else-if="props.column.field === 'price'">
              {{ Number(props.row.price).toLocaleString() }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị tối đa </span>
                <b-form-select
                  v-model="serverParams.perPage"
                  :options="['10', '20', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  trên tổng số {{ props.total }} kết quả
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="serverParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
          <div slot="emptystate">
            <div class="text-center text-muted">
              Không có bản ghi nào!
            </div>
          </div>
        </vue-good-table>
      </b-col>
    </b-row>
    <fee-modal
      :item="{}"
      @refetch-data="fetchFees"
    />
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BModal,
  BButton,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import SelectFeeType from "@/views/components/SelectFeeType.vue";
import FeeModal from "@/views/services/fee/modal/FeeModal.vue";
import useListServiceModal from "./useListServiceModal";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BModal,
    BButton,
    VueGoodTable,
    SelectFeeType,
    FeeModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    feesSelected: {
      type: Array,
      default: null,
    },
    apartment: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props) {
    const {
      columns,
      rows,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchFees,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resetValue,
    } = useListServiceModal(props);

    return {
      columns,
      rows,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchFees,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      resetValue,
      selectionChanged,
    };
  },

  methods: {
    handleSubmit() {
      this.$emit("selectService", this.selectedRows);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
