import { ref, watch } from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';

export default function useListServiceModal(props) {
  // Use toast
  const toastification = toast();

  // Table Handlers
  const columns = [
    {
      label: 'Tên dịch vụ',
      field: 'name',
      width: '130px',
    },
    {
      label: 'Loại phí',
      field: 'type.title',
      width: '200px',
    },
    {
      label: 'Đơn giá',
      field: 'unit',
      width: '200px',
    },
    {
      label: 'Giá tiền',
      field: 'price',
      width: '130px',
    },
  ];
  const rows = ref([]);
  // filter
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {
      apartmentId: props.apartment ? props.apartment.id : null,
    },
    sort: {},
    page: 1,
    perPage: 100,
  });

  //   API Call
  const fetchFees = () => {
    serverParams.value = {
      searchTerm: '',
      filter: {
        apartmentId: props.apartment ? props.apartment.id : null,
      },
      sort: {},
      page: 1,
      perPage: 100,
    };
    store
      .dispatch('contract/fetchFees', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        const data = [...items.filter(res => !['lease', 'deposit'].includes(res.feeCategory.id))];
        if (props.feesSelected && props.feesSelected.length) {
          const listIdFeesSelected = props.feesSelected.map(tenant => tenant.id);
          const dataRes = data.filter(res => !listIdFeesSelected.includes(res.id));
          rows.value = [...dataRes];
        } else rows.value = data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchFees();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const resetValue = () => {
    rows.value = [];
    // filter
    type.value = null;
    // current selected rows
    selectedRows.value = [];
    searchTerm.value = '';
    // ssr
    isLoading.value = false;
    totalRecords.value = 0;
    serverParams.value = {
      searchTerm: '',
      filter: {},
      sort: {},
      page: 1,
      perPage: 20,
    };
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });

  return {
    columns,
    rows,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchFees,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    resetValue,
  };
}
